.button {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'MyFont', monospace;
    color: white;
    transform-origin: center center;
}

.top-left:hover {
    transform-origin: bottom right;
    z-index: 1000; /* Ensure it appears above other elements when scaled */
}

.top-right:hover {
    transform-origin: bottom left;
    z-index: 1000; /* Ensure it appears above other elements when scaled */
}

.bottom-left:hover {
    transform-origin: top right;
    z-index: 1000; /* Ensure it appears above other elements when scaled */
}

.bottom-right:hover {
    transform-origin: top left;
    z-index: 1000; /* Ensure it appears above other elements when scaled */
}

.top-left {
    top: 25%;
    left: 35%;
}

.top-right {
    top: 25%;
    right: 35%;
}

.bottom-left {
    bottom: 25%;
    left: 35%;
}

.bottom-right {
    bottom: 25%;
    right: 35%;
}

.button img {
    height: auto;
}

.button span {
    margin-top: 8px;
    font-size: 16px; /* Adjust as needed */
}

.infoButton {
    position: absolute;
    z-index: 10;
    top:0;
    left:0;
}

.infoButton img{
    width: 20vh;
}

@media screen and (orientation: portrait) {

    .button img {
        width: 120px;
    }
}

.loadingImage{
    width: 10%;
}


/* Responsive adjustments */
@media (max-width: 1100px) {
    .button {
        font-size: 18px;
    }

    .button img {
        width: 60px;
    }

    .top-left {
        top: 20%;
        left: 20%;
    }
    
    .top-right {
        top: 20%;
        right: 20%;
    }
    
    .bottom-left {
        bottom: 20%;
        left: 20%;
    }
    
    .bottom-right {
        bottom: 20%;
        right: 20%;
    }

    .loadingImage{
        width: 25%;
    }
}

